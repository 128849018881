.ServiceBox{
    border-radius: 10px;
    border: 3px solid rgba(0,0,0,.125);
    padding: 20px 30px 20px 30px;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    background-image: url("https://img.freepik.com/free-vector/white-abstract-background_23-2148817571.jpg?size=626&ext=jpg&ga=GA1.2.505893332.1601538018");
}

@media screen and (max-width: 480px) {
    .ServiceBox{
        margin-top: 2px;
    }
}

.BlackText{
    font-family: (--textfont);
}

