.iconsBenchMark{
    font-size: 50px;
    color: black;
}
.HeaderBenchMark{
    padding-top: 18px;
    padding-bottom: 18px;
}
/* @keyframes zoomIn {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  .IndustriesWeServeBox {
    animation: zoomIn 0.5s ease-out; 
  }
  .IndustriesWeServeBox:hover {
    transform: scale(1.1);
  } */
  
@media screen and (max-width: 480px) {
    .benchmarkbox {
      padding-bottom: 20px;
    }
}