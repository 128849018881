.AboutWhoWeAre{
    padding: 10px;
}
/* .row {
    width: 99vw !important;
} */

.About{
    width: 100%;
    height: auto;
    padding-bottom: 100px;
    padding-top: 100px;
    background-color: #22b7e9;    
    font-size: 55px;
    color: white;
    font-family: Gilroy,-apple-system,BlinkMacSystemFont,Raleway,sans-serif!important;
    font-weight: 600;
    text-align: center;    
}

@media screen and (max-width: 480px) {
    .About {
        font-size: 20px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.Awesome{
    margin-top: 60px;
    padding: 20px 10px 10px 10px;
    text-align: center;
    font-size: 54px;
    line-height: 64px;
    font-family: Gilroy,-apple-system,BlinkMacSystemFont,Raleway,sans-serif!important;
    font-weight: 600;
}

@media screen and (max-width: 480px) {
    .Awesome {
        margin-top: 10px;
        font-size: 34px;
}
}

.OurCoreValues{
    margin-top: 50px;
    padding: 10px;
}

@media screen and (max-width: 480px) {
    .OurCoreValues {
        margin-top: 50px;
        font-size: 34px;
        margin-bottom: 0;
        padding: 0;
}
}

.Who_we_are{
    font-family: Gilroy,-apple-system,BlinkMacSystemFont,Raleway,sans-serif!important;
    padding: 20px 10px 20px 10px; 
    padding-right: 40px;
    text-align: justify;
}

@media screen and (max-width: 480px) {
    .Who_we_are {
        padding-right: 0;       
}
}

.Who_we_are_img{
    width: 100%;
    border-radius: 2%;
}

.border-design{
    content: '';
    width: 185px;
    height: 185px;
    position: absolute;
    border-top: 2px solid #000;
    border-left: 2px solid #000;
    right: 0;
    top: 0
}

.Underline_CoreValues {
    margin-top: 5px;
    height: 3px;
    width: 255px;
    background: rgb(231, 112, 33);
  }

  .Underline_WWhoWeAre{
    margin-top: 5px;
    height: 3px;
    width: 155px;
    background: rgb(231, 112, 33);
  }