.text-box-jobapply-job-apply{
    margin-top: 9px;
    margin-bottom: 9px;
    
}

/* .job-applyform-getjob-prfectkode{
    padding:20px;
} */





.jobapply-get-job-perfectkode{
    padding: 20px !important;
    border: 2px black;
    border-radius: 10px;
    box-shadow: 0 0 23px rgb(0 0 0 / 15%);
    
}
