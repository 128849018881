* {
    margin: 0;
    padding: 0;
}



.banner-head {
    padding: 20px 20px 40px;
    background-color: #22b7e9;
    color: white;


}

.ContactCareerBtn {
    font-size: 18px;
    padding: 13px 40px 13px 40px;
    margin-top: 10px;
    background-color: black;
    color: white;
}

.heading-career {
    font-size: 3rem;
    text-transform: uppercase !important;
    margin-bottom: 1.5rem !important;
    color: var(--heading-color);
    font-weight: 700;
    box-sizing: border-box;
    padding-top: 40px;
    margin-bottom: 0.5rem;

    line-height: 1.2;
}

.head-head {

    padding-top: 50px
}

.office-office {
    width: 100%;
    border-radius: 2%;
}

.BannerLifeAtPerfectkode {
    width: 100%;
}

/* @media screen and (max-width: 480px) {
    .BannerLifeAtPerfectkode {
        width: 100%;

    }
} */