.Centre_align_banner_text {
  position: relative;
  text-align: center;
}

.flip-left {
  text-align: justify;
}

.HomepageBussinessContent {
  text-align: justify;
  font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
}

@media screen and (max-width: 680px) {
  .HomepageBussinessContent {
    padding-bottom: 20px;
  }
}

.FirstImageStaffing {
  width: 50%;
  margin: auto;
  display: flex;
}

@media screen and (max-width: 680px) {
  .FirstImageStaffing {
    width: 75%;
  }
}

.staffing {
  width: 50%;
  margin: auto;
  display: flex;
}

@media screen and (max-width: 680px) {
  .staffing {
    width: 75%;
  }
}

@media screen and (max-width: 680px) {
  .staffing-flex-d {
    flex-direction: column-reverse;
  }
}

.border-small {
  margin-bottom: 5px;
  height: 3px;
  width: 75px;
  background: rgb(231, 112, 33);
}

.border-big {
  margin-top: 5px;
  height: 3px;
  width: 130px;
  background: rgb(231, 112, 33);
}

.extraspace {
  padding-top: 40px;
}
@media screen and (max-width: 320px) {
  .extraspace {
    padding: 14px;
  }
}

@media screen and (min-width: 320px) {
  .extraspace {
    padding: 24px;
  }
}

.section1 {
  position: relative;
  height: 100%;
  overflow: hidden;
  width: 100% !important;
}

.banner-logo {
  position: absolute;
  top: 20%;
  left: 40%;
}

.banner-heading {
  position: absolute;
  left: 30%;
  top: 23%;
  color: rgb(231, 112, 33);
  font-size: 8rem;
  font-weight: 700;
}

.banner1 {
  width: 100% !important;
}

.static-txt {
  width: 100%;
  position: absolute;
  top: 30%;
  color: #2cc9fc;
  font-size: 60px;
  font-weight: 700;
}

.static-txt-perfect {
  display: flex;
  position: absolute;
  top: 55%;
  left: 31%;
  right: 25%;
  color: #2cc9fc;
  font-size: 60px;
  font-weight: 700;
}

@media screen and (max-width: 480px) {
  .static-txt-perfect {
    right: 23%;
    text-align: center !important;
    font-size: 18px;
    font-weight: 700;
    top: 25%;
    left: 45%;
  }
}

@media screen and (max-width: 880px) {
  .banner1 {
    height: 100%;
  }

  .static-txt {
    position: absolute;
    top: 21%;
    width: 100%;
    color: #2cc9fc;
    font-size: 30px;
    font-weight: 700;
    left: 0;
    right: 0;
    display: block;
  }
}

.dynamic-txts {
  width: 100%;
  position: absolute;
  /* left: 40%; */
  top: 47%;
  height: 90px;
  line-height: 90px;
  overflow: hidden;
}

ol,
ul {
  padding-left: 0rem !important;
}

@media screen and (max-width: 880px) {
  .dynamic-txts {
    position: absolute;
    width: 100%;
    height: 90px;
    line-height: 90px;
    overflow: hidden;
    text-align: center !important;
    top: 30%;
    left: 0;
  }

  ol,
  ul {
    padding-left: 0px !important;
  }
}

.dynamic-txts li {
  /* text-align: left; */
  list-style: none;
  color: rgb(231, 112, 33);
  font-size: 70px;
  font-weight: 700;
  position: relative;
  top: 0;
  animation: slide 6s steps(4) infinite;
}

@media screen and (max-width: 880px) {
  .dynamic-txts li {
    font-size: 30px !important;
    font-weight: 700 !important;
    text-align: center;
  }
}

@keyframes slide {
  100% {
    top: -360px;
  }
}

.dynamic-txts li span {
  position: relative;
  margin: 5px 0;
  line-height: 90px;
}

.dynamic-txts li span::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background: none;
  /* border-left: 2px solid orange; */
  animation: typing 3s steps(10) infinite;
}

@keyframes typing {
  40%,
  60% {
    left: calc(100% + 30px);
  }

  100% {
    left: 0;
  }
}

.section2 {
  padding-top: 50px;
  padding-bottom: 70px;
}

@media screen and (max-width: 480px) {
  .section2 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.bounce {
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .Mobileimg {
    padding: 30px;
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-30px);
  }
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-30px);
  }
}

@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-30px);
  }

  60% {
    -o-transform: translateY(-30px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-30px);
  }
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 94%;
  margin: 50px !important;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.vtimeline-content {
  background: #fff;
  border: 1px solid #e6e6e6;
  padding: 35px 20px;
  border-radius: 3px;
  text-align: left;

  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.3);
}

.vtimeline-content h3 {
  font-size: 1.5em;
  font-weight: 600;
  display: inline-block;
  margin: 0;
}

.vtimeline-content p {
  font-size: 0.9em;
  margin: 0;
}

.vtimeline-point {
  position: relative;
  display: block;
  vertical-align: top;
  margin-bottom: 30px;
}

.vtimeline-block {
  margin-bottom: 50px;
}

.vtimeline-icon {
  position: relative;
  color: #fff;
  width: 50px;
  height: 50px;
  background: #4782d3;
  border-radius: 50%;
  float: left;
  text-align: center;
  line-height: 50px;
  z-index: 99;
  margin-left: 280px;
}

.vtimeline-icon i {
  display: block;
  font-size: 1.5em;
  line-height: 50px;
}

.vtimeline-date {
  width: 260px;
  text-align: right;
  position: absolute;
  left: 0;
  top: 10px;
  font-weight: 400;
  color: #374054;
}

.post-meta {
  padding-top: 15px;
  margin-bottom: 20px;
}

.post-meta li:not(:last-child) {
  margin-right: 10px;
}

h3 {
  font-family: "Montserrat", sans-serif;
  color: #252525;
  font-weight: 700;
  font-variant-ligatures: common-ligatures;
  margin-top: 0;
  letter-spacing: -0.2px;
  line-height: 1.3;
}

.mb20 {
  margin-bottom: 20px !important;
}

.section3 {
  padding-bottom: 30px;
}

.BussinessHome {
  padding-right: 20px;
  padding-top: 10px;
}

@media screen and (max-width: 480px) {
  .BussinessHome {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* @media screen and (max-width: 480px) {
  .extraspace {
    padding-left: 10px;
    padding-right: 10px;
  }
} */

.HomePageServiceContent {
  text-align: justify;
  font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
}

.HomePageReadMore:link {
  text-decoration: none;
  color: #2cc9fc;
}

.HomePageReadMore:hover {
  text-decoration: underline;
  color: rgb(231, 112, 33);
}

.HomeServicesHeight {
  height: 100%;
}

.margin-auto-animate {
  margin: auto;
}

.margin-bottom-20px-fix {
  margin-bottom: 30px;
  padding-top: 20px;
}

.padding-top-bottom-20px {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.padding-bottom-20px {
  padding-bottom: 20px;
}
.seleacted-color {
  color: rgb(231, 112, 33) !important ;
}
.Serviceheading h2 {
  font-size: 36px;
  line-height: 48px;
  color: rgb(65, 64, 64);
  font-weight: 900;
  margin-top: 10%;
  margin-bottom: 5%;
}

.Technology-Text {
  /* text-align: "center" !important;
  margin-bottom: "50px" !important;
  font-size: "22px" !important;
  font-weight: "200" !important; */
  text-align: center;
  margin-bottom: 50px;
  font-size: 22px;
  font-weight: 200;
}

.About-Text {
  text-align: justify;
}
.About-Head {
  margin-bottom: 20px;
}
.About-banner {
  margin-bottom: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f6f6f6;
  margin-top: 10px;
}
.margin-40px{
  margin-top: 40px;
}
.banner-about{
   height: 100%;
   width: 100%;
   border-radius: 2%;
}
.About-Text-Box{
  padding-left: 30px !important;
  padding-right: 30px !important;
}

@media screen and (max-width: 600px) {
  .About-Text-Box{
    padding-top: 30px !important;
  }
}
