.PortfolioCardTitle{
    text-align: center;
}
.card-img-overlay {
  
    top: 50% !important;
   
    bottom: 50% !important;
   
}

.PortfolioCategories{
    background: black;
    opacity: 0.5;
    padding: 10px;
}

.MarginBottom2rem{
    margin-bottom: 2.5rem;
}

