.GetaQuoteBanner {
    width: 100%;
    height: auto;
    padding-bottom: 100px !important;
    padding-top: 100px !important;
    background-color: #22b7e9;
    font-size: 55px;
    color: white;
    padding: auto;
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
    font-weight: 600;
    text-align: center;

}


@media screen and (max-width: 480px) {
    .GetaQuoteBanner {
        font-size: 20px;
        font-weight: 700;
        padding-bottom: 50px;
        padding-top: 50px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.GetquoteBtn {
    display: inline;
    padding: 15px;
}

.GetaQuoteFormMain {
    padding-top: 20px;
    border: 2px black;
    border-radius: 10px;
    box-shadow: 0 0 23px rgb(0 0 0 / 15%);
    padding-left: 10px;
    padding-right: 10px;
}

.GetaQuoteFormAboveMain {
    padding: 30px;

}



.GetaQuoteSubmitBtn {
    font-size: 18px;
    padding: 13px 40px 13px 40px;
    margin-top: 10px;
    background-color: black;
    color: white;

}

.btn-align {
    text-align: center;
    padding-bottom: 20px;

}

.QuoteBar {
    background: rgb(231, 112, 33);
    width: 20%;
    border-radius: 5px;
    height: 5px;
    margin: auto;
}

.HeaderAddress {
    text-align: center;
}

.Adressmain {
    padding-top: 50px;
}

.AdressRow {
    padding-top: 40px;
    text-align: center;
}


.PerfectKodeGetQuote {
    color: #22b7e9;
}

.GetQuoteWhyPerfectKodeText {
    padding-top: 10px;
}


.text-box {
    width: 100%;
    padding: 8px;
    outline: none;
    border: 1px solid #c2c2c2;
    border-radius: 5px;
}