.container-secondpart-image-text {
    padding: 60px;
    background-color: #f6f6f6;
}

.orange-hradinhead {
    padding-bottom: 20px;
    padding-left: 10px;
}

.right-side-image-back {
    top: 0;
    right: 0;
    width: 180px;
    object-fit: cover;
    position: absolute;
    height: 400px;
}

.left-side-image-back {
    bottom: 0;
    left: 0;
    width: 90px;
    position: absolute;
    height: 400px;

}

.text-text-part-three {
    text-align: center !important;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);

}


.digital-marketing-text-para {
    text-align: center !important;

    margin-right: auto !important;
    margin-left: auto !important;
}

.prof-digital-marketing-service {
    padding: 60px;

}

.professional-heading {
    font-stretch: expanded;
}

.professional-head-orange-color {
    color: orange !important
}

.iconsmarketing {
    font-size: 50px;
    color: #ff00a9;
}

.HeaderBenchMark {
    padding-top: 18px;
    padding-bottom: 18px;
}

@media screen and (max-width: 480px) {
    .market {
        padding-bottom: 20px;
    }
}

.market {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center !important;

}

.icons-card-icons {
    padding: 0PX 50px;
}

.text-benefits-of-bussiness {
    margin-right: auto !important;
    margin-left: auto !important;
}

@media (min-width: 768px) {
    .col-md-6 {
        flex: 0 0 auto;
        width: 50%;
    }
}

.orange-color-heading {
    color: orange;
}

@media (min-width: 768px) {
    .col-md-12 {
        flex: 0 0 auto;
        width: 100%;

    }
}

.points-poionts {
    padding-top: 5px;
    padding-bottom: 5px;
}


.why-choose-us-as-digital-partner {
    margin-top: 30px;
    margin-bottom: 20px;
}


.icons-eight-we-beleive-in-success {
    background-color: #f6f6f6;
    padding: 60px;
}




.we-believe-digital, .we-believe-digital-fluid, .we-believe-digital-lg, .we-believe-digital-md, .we-believe-digital-sm, .we-believe-digital-xl, .we-believe-digital-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto;
}

.description-believe-in-success {
    color: var(--txt-light);
    font-size: var(--text-large);
}


.positive-m30 {
    margin-bottom: -30px;
}

.text-center {
    text-align: center !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);

}


.d-flex {
    display: flex !important;
}

@media (min-width: 768px) {
    .col-md-4 {
        flex: 0 0 auto;
    }
}

.animation-coloured-boxes-purple {
    padding: 10px;
    box-shadow: 0 40px 40px rgb(197 195 249 / 15%);
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    display: inline-block;
    width: 100%;
    border: 1px solid #f7f7f7b3;
    transition: all .2s linear;
    margin-bottom: 30px;




}



.hours-of-work-purple {
    box-shadow: 0 0.5rem 1rem rgb(92 85 216 / 30%);

    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bolder;
    font-size: 25px;
    background: linear-gradient(#f45ae0, #87459d);


}



.working-hours {
    margin-bottom: 0 !important;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: bolder;
    font-size: 25px;


}

.hours-of-work-lightgreen {
    box-shadow: 0 0.5rem 1rem rgb(92 85 216 / 30%);

    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bolder;
    font-size: 25px;
    background: linear-gradient(#95ffbc, #81ff62);
}



.hours-of-work-orange {
    box-shadow: 0 0.5rem 1rem rgb(92 85 216 / 30%);

    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bolder;
    font-size: 25px;
    background: linear-gradient(#f5db67, #badb00);
}




.hours-of-work-green {
    box-shadow: 0 0.5rem 1rem rgb(92 85 216 / 30%);

    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bolder;
    font-size: 25px;
    background: linear-gradient(#dc9774, #ad341c);
}

.hours-of-work-bluegradient {
    box-shadow: 0 0.5rem 1rem rgb(92 85 216 / 30%);

    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bolder;
    font-size: 25px;
    background: linear-gradient(#ea4141, #f36fb8);
}


.hours-of-work-yellow {
    box-shadow: 0 0.5rem 1rem rgb(92 85 216 / 30%);

    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bolder;
    font-size: 25px;
    background: linear-gradient(145deg, #EBFF80, #F8816E);



}

.hours-of-work-bluelightgradient {
    box-shadow: 0 0.5rem 1rem rgb(92 85 216 / 30%);

    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bolder;
    font-size: 25px;
    background: linear-gradient(#A4C1FB, #4AD4FF);
}

.hours-of-work-darkbluegradient {
    box-shadow: 0 0.5rem 1rem rgb(92 85 216 / 30%);

    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bolder;
    font-size: 25px;
    background: linear-gradient(#4386D2, #0962C5);
}

.para-para-para {
    text-align: justify;
    padding-left: 10px;
    padding-right: 10px;
}

.cc-para-para-para-cc {
    text-align: justify;
    padding-left: 20px;
    padding-right: 20px;
}


.online {
    margin-top: 20px;
}


.benefits-of-digital-marketing {
    padding-top: 20px;
}


.h-down-line-under {
    margin-top: 5px;
    height: 3px;
    width: 250px;
    margin-bottom: 30px;
    background: rgb(231, 112, 33);
}

.h-down-line-under-hhh {
    margin-top: 5px;
    height: 3px;
    width: 250px;
    margin-bottom: 30px;
    background: rgb(231, 112, 33);

  }

  .h-down-line-under-www{
    margin-top: 5px;
    height: 3px;
    width: 260px;
    margin-bottom: 30px;
    background: rgb(231, 112, 33);

  }

  .h-down-line-under-bbb{
    margin-top: 5px;
    height: 3px;
    width: 270px;
    margin-bottom: 30px;
    background: rgb(231, 112, 33);

  }


.iconsmarketingContent {
    font-size: 50px;
    color: #0077ff;
}

.iconsmarketingexpert{
    font-size: 50px;
    color: #ff00a9;
}

.DigitalprocessImg{
    width: 100% !important;
}




