* {
    margin: 0;
    padding: 0;
}

.image-training-image-qa {
    height: 60%;
    padding-bottom: 20px;
    margin: auto;
    display: flex;
    padding-top: 50px;
}

.container-qa-testing-qa {
    /* background-color: #f6f6f6; */
    height: 40%;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
}

.text-heading-qa-services-text {
    font-size: 40px;
    font-family: sans-serif;
    font-weight: normal;
    color: black;
    text-align: center;
    padding-bottom: 10px;
}

.head-qa-heading-test {
    background-color: #f6f6f6;
    font-size: inherit;
    font-style: normal;
    font-weight: normal;
    text-align: justify;
}


.training-services-automation-test {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.Automation-qa-test-automation {
    /* background-color: #f6f6f6; */
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
    height: 80%;
}
.contentQA {
    padding-top: 18px;
    text-align: justify;
    /* padding-left: 80px;
    padding-right: 80px; */
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
}

.robot-automation-process {
    /* background-color: #f6f6f6; */
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
}

.qa-testing-qa-manual-testing {
    /* background-color: #f6f6f6; */
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 15px;

}

.image-training-image-qa-robotics.image-training-image-qa-performance.image-training-image-qa-manual {
    padding: 20px;
}

.image-training-image-qa-robotics {
    display: flex;
    margin: auto;
}

.image-training-image-qa-performance {
    padding: 20px;
    padding-left: 20px;
    width: 90%;
    margin: auto;
    display: flex;
}

.image-training-image-qa-manual {
    height: 60%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    margin: auto;
    display: flex;
}

.training-services {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding-left: 15px;
    padding-right: 15px;
}

.para-robot-para {
    padding-top: 20px;
}

.image-training-image-qa-services {
    margin: auto;
    display: flex;
    padding-top: 5px;
}

.rii {
    padding-bottom: -5px;
}

.image-training-imageqa-performance {
    display: flex;
    margin: auto;
    height: 100%;
    width: 100%;
}


.Auto-testing-service {
    text-align: center;
    padding-top: 40px;
}