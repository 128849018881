
 .H1HeaderBlog{
    text-align: center;
    padding: 20px;
  }

  h1{
    text-align: center;
    padding: 10px;
  }
  
  h2 {
    text-align: center;
    padding: 10px;
  }
  .BlogHeading{
    padding: 20px 0px;
    font-size: 2rem;
  }

  .BlogBox{
        background: #fff;
        box-shadow: 0 0 23px rgb(0 0 0 / 10%);
        text-align: center;
        border-radius: 15px;
        padding: 10px;
    }

    @media screen and (max-width: 480px) {
      .BlogHeading {
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
      }
  }

  .padding-bottom-10px{
    padding-bottom: 10px;
  }
    .Blogimg{
        font-size: 50px;
        color: black;
    }
  .textAlign{
    text-align: justify;
  }
  .textAlign img {
    
    display: block;
    margin: 0 auto; 
  }
  

    @media screen and (max-width: 480px) {
        .BlogBoxMain {
          padding-bottom: 20px;
        }
    }

.height-100{
  height: 100%;
}

.Button-margin{
  padding-bottom: 18px;
  width: 100%;
}
.margin-bottom-2rem{
  padding-left: 30px;
    padding-right: 30px;

}

.blog-blog-page{
  margin-top: 30px !important;
  padding: 10px;
}

.BlogBoxMain{
  margin-bottom: 20px;
}

.PaddingBottom30px{
  padding-bottom: 30px;
}
.latest-blog{
  display: flex;
  justify-content: start;
}
.search-input{
  padding-left: 5px;
  border: none;
  border-bottom: 1px solid !important;
  margin-bottom: 20px;
}
.category-box{
  display: flex;
  flex-direction: column;
  align-items: end;
}
.content-category{
  color: black;
  cursor: pointer;
}

.content-category:hover{
  color: rgb(231, 112, 33) !important;
}

@media screen and (max-width: 480px) {
  .category-box{
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 30px;
    padding-left: 20px;
  }
}
.Blog-Head{
  margin-top: 10px;
  text-align: justify;
}
.Blog-Text{
  text-align: justify;
}

@media screen and (min-width: 600px){
  .blog-card{
    padding-left: 30px !important;
  }
  
}

.help-card{
  border-radius: 10px;
    border: 3px solid rgba(0,0,0,.125);
    padding: 20px 30px 20px 30px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 80px;
    text-align: center;
    background-image: url("https://img.freepik.com/free-vector/white-abstract-background_23-2148817571.jpg?size=626&ext=jpg&ga=GA1.2.505893332.1601538018");
}
@media screen and (max-width: 600px){
  .help-card{
    border-radius: 10px;
    border: 3px solid rgba(0,0,0,.125);
    padding: 20px 30px 20px 30px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 0px !important;
    text-align: center;
    background-image: url("https://img.freepik.com/free-vector/white-abstract-background_23-2148817571.jpg?size=626&ext=jpg&ga=GA1.2.505893332.1601538018");
  }
  
}

.help-button{
  text-decoration: none;
  color: #fff;
  background-color: black;
  border: 1px solid rgb(169, 169, 169);
  padding-left: 10px;
  padding-right: 10px;
}