
.detailportfolio-information{
    padding:20px;
}


.details-portfolio-heading3-detail{
    padding-left: 10px;
    padding-right: 10px;
}


.information-project-top-head-aboutproject{
    margin-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;

}