.margin-autos-w100{
    width: 100%;
    text-align: center;
    padding: 24px;
}
.numberstyle404{
font-size: 10rem;
color: #f2973c;
font-weight: bold;
}
.oops-all-design{
    font-family: sans-serif;
    font-size: 20px;
    color: #2bc9fc;
}
.backtohome-page{
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid gray;
    border-radius: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: sans-serif;
    font-weight: bold;

}
