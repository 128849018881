.Why_perfectkode {
    width: 100%;
    height: 100%;
    padding-bottom: 65px;
    padding-top: 55px;
    background-color: rgb(231, 112, 33);
    font-size: 35px;
    color: white;
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
    font-weight: 600;
    text-align: center;
}

@media screen and (max-width: 480px) {
    .Why_perfectkode {
        font-size: 20px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.WhyUsImg {
    width: 100%;
    border-radius: 2%;
}

.WhyUsContent {
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
    padding: 50px 10px 10px 10px;
    text-align: justify;
}

.WhyUsContentReason{
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
    padding: 10px 10px 10px 10px;
    text-align: justify;
}

.WhyUsContainer1 {
    margin-top: 55px !important;
    margin-bottom: 55px;
}

@media screen and (max-width: 480px) {
    .WhyUsContainer1 {
        margin-top: 15px !important;
        margin-bottom: 20px;
    }
}

.whyChooseUsH2{
    text-align: left;
    padding: 10px;
}

.Onclicks {
    text-decoration: none;
    display: flex;
   padding-left: 10px;
}

@media screen and (max-width: 480px) {
    .Onclicks {
       padding: 10px;
       padding-bottom: 0;
       font-size: small;
    }
}

.Onclicks a {
    text-decoration: none;
    color: black;
}

.Onclick_People {
    padding: 10px;
    padding-left: 0;
    color: #2cc9fc;
    cursor: pointer;
}

.Onclick_People :hover {
    color: rgb(231, 112, 33);
}

.Onclick_Process {
    cursor: pointer;
    padding: 10px;
    color: #2cc9fc;
}

.Onclick_Process :hover {
    color: rgb(231, 112, 33);
}

.Onclick_Pesponsiveness {
    padding: 10px;
    padding-right: 0;
    color: #2cc9fc;
    cursor: pointer;
}

.Onclick_Pesponsiveness :hover {
    color: rgb(231, 112, 33);
}

.active_person :active {
    color: rgb(231, 112, 33);
}

.WhyUsMain {
    margin-bottom: 40px;
}