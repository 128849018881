.container-job-details-job-apply-get{
    padding:20px;
    text-align: justify;
}

.responsibilities-heading-job-heading{
    padding-bottom: 20px;
    margin-top: 20px;
}

.jobbox{
    cursor: pointer;
}

.jobbox:hover{
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.Underline_Jobrole{
    margin-top: 5px;
    height: 3px;
    width: 400px;
    background: rgb(231, 112, 33);
}

@media screen and (max-width: 480px) {
    .Underline_Jobrole{
        width: 170px;
    }
}