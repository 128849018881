.card {

  max-width: 100%;
  margin: 0px !important;
  text-align: justify;
  font-family: arial;
  width: 100% !important;
}

.price {
  color: grey;
  font-size: 22px;
}

.card button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.tj {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  box-shadow: 0 0 6px 1px rgb(0 0 0 / 10%);
  height: 650px !important;
}

.card-heading-color {
  font-size: 28px;
  font-style: normal;
  padding-top: 18px;
  color: #ff5000 !important;
}

.card-para-career-traing-web-java-front-back {
  font-family: Open Sans, Helvetica Neue, Arial, Helvetica, sans-serif !important;
  font-size: 16px;
  line-height: 25px;
  font-weight: 300;
  font-style: normal;
  color: #4d4d4d;
  margin-bottom: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 5 !important;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: justify;
}

.secondline-tp {
  padding-top: 30px;
  padding-bottom: 30px;
}

.cart-button-cart-add-button {
  background: black;
  text-decoration: none;
  color: white;
  padding: 10px;
  bottom: 5px !important;
  position: absolute;
  right: 5px;

}

.TrainingCartimg {
  width: 100%;
  height: 100%;
}

.courses-train {
  margin-bottom: 30px !important;
}

.image-card {
  height: 300px;
  width: 100%;
  background-repeat: no-repeat, no-repeat;
  background-position: center, center;
  background-size: contain;

}

.Divhead{
  padding-top: 50px;
  padding-bottom: 10px;
}
