.WebDevlopment {
    width: 100%;
    height: auto;
    padding-bottom: 100px;
    padding-top: 100px;
    background-color: #22b7e9;
    font-size: 55px;
    color: white;
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
    font-weight: 600;
    text-align: center;
}
.h-down-line-under1 {
    margin-top: 5px;
    height: 3px;
    width: 400px;
    margin-bottom: 30px;
    background: rgb(231, 112, 33);
}
.h-down-line-under2 {
    margin-top: 5px;
    height: 3px;
    width: 500px;
    margin-bottom: 30px;
    background: rgb(231, 112, 33);
}

.h-down-line-under3 {
    margin-top: 5px;
    height: 3px;
    width: 400px;
    margin-bottom: 30px;
    background: rgb(231, 112, 33);
}
.h-down-line-under4 {
    margin-top: 5px;
    height: 3px;
    width: 600px;
    margin-bottom: 30px;
    background: rgb(231, 112, 33);
}
.h-down-line-under6 {
    margin-top: 5px;
    height: 3px;
    width: 700px;
    margin-bottom: 30px;
    background: rgb(231, 112, 33);
}
.row {
    --bs-gutter-x: 1.1rem !important;}

@media screen and (max-width: 480px) {
    .row {
        --bs-gutter-x: -0.5rem !important;}
    .WebDevlopment {
        text-align: center;
        padding-right: 20px;
        padding-left: 20px;
        font-size: 25px;
        padding-bottom: 70px;
        padding-top: 50px;
    }
}

.WebDevCon2top24{
    padding-top: 24px;
    padding-bottom: 24px;
}
.WebHeader {
    padding-top: 50px;
}

.cutom-shadow {
    color: #df712c;
    position: relative;
    top: 40px;
}

.text-center {
    text-align: center !important;
}

sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

.cutom-shadow sup {
    top: -0.5em;
    color: #7c7c7c;
    font-size: 50px;
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
}


.orange-text {
    color: #e35d25 !important;
    font-weight: 74px !important;
}

.cutom-shadow span {
    text-shadow: 0 -33px 0 rgb(150 150 150 / 9%);
    font-size: 74px;
}

.No_text {
    color: #333;
    margin-bottom: 40px;
    text-align: center;
    padding-left: 1rem !important;
}

.black-text {
    text-align: center;

}

.WebDevCon1 {
    background-color: #f6f6f6;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 18px;
}
@media screen and (max-width: 480px) {
    .WebDevCon1 {
        padding-top: 10px;
    }
}

.contentWebDev {
    padding-top: 18px;
    text-align: justify;
    padding-left: 80px;
    padding-right: 80px;
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
}

.contentWebDevFQA{
    justify-content: center;
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
}

@media screen and (max-width: 480px) {
    .contentWebDev {
        padding-left: 15px;
        padding-right: 15px;
        text-align: justify;
    }
}

.WebDevCon2 {
    text-align: center;
    padding-top: 45px;
    padding-bottom: 25px;
}

.CartMid{
    padding-top: 15px;
}

.contentWebRedesign{
    padding-top: 30px;
    text-align: left;
    padding-left: 80px;
    padding-right: 80px;
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;  
}

.RedesignBannerImg{
    width: 100%;
}

@media screen and (max-width: 740px) {
    .h-down-line-under1 {
        margin-top: 5px;
        height: 3px;
        width: 170px;
        margin-bottom: 30px;
        background: rgb(231, 112, 33);
    }
    .h-down-line-under2 {
        margin-top: 5px;
        height: 3px;
        width: 170px;
        margin-bottom: 30px;
        background: rgb(231, 112, 33);
    }
    .h-down-line-under3 {
        margin-top: 5px;
        height: 3px;
        width: 170px;
        margin-bottom: 30px;
        background: rgb(231, 112, 33);
    }
    .h-down-line-under4 {
        margin-top: 5px;
        height: 3px;
        width: 200px;
        margin-bottom: 30px;
        background: rgb(231, 112, 33);
    }
    .h-down-line-under6 {
        margin-top: 5px;
        height: 3px;
        width: 200px;
        margin-bottom: 30px;
        background: rgb(231, 112, 33);
    }
    .contentWebRedesign {
        padding-left: 15px;
        padding-right: 15px;
        justify-content: center;
        text-align: justify;
    }
    .typewriter-text {
        width: 50vw;
        max-width: 90%;
        display: flex;
        flex-wrap: wrap;
        white-space: pre-inline;
        overflow: hidden;
        color: #191515;
        font-family: monospace;
        font-size: 0.5rem !important;
        position: absolute;
        top: 50%;
        left: 28%;
        transform: translate(-50%, -50%);
        text-align: center;
        padding: 10px;
    }
}
@media (min-width: 576px)
{
    .WebDevCon2paddingnone{
        padding-top: 30px;
    }
}