.industriesParagraph1{
    padding-top: 30px;
    text-align: justify;
}

.IndustriesWeServeHeading{
    text-align: center;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.IndustriesWeServeBox{
    border: 1px black;
    width: auto;
    height: 100%;
    background: #fff;
    box-shadow: 0 0 23px rgb(0 0 0 / 10%);
    text-align: center;
    border-radius: 15px;
    padding: 30px;
}

.IndustriesWeServeBox:hover  {
    /* transform: rotateY( 60deg ) ; */
    /* transform: rotateY(360deg);
    transition: transform 1.8s; */
  }

  @media screen and (max-width: 680px) {
    .IndustriesWeServeBox:hover  {
        /* transform: rotateY( 60deg ) ; */
        transform: none;
        transition: none;
      }
  }

.Boxrow1{
    padding: 13px;
}

@media screen and (max-width: 480px) {
    .Boxrow1 {
        padding: 2px;
    }
}

.icons{
    font-size:90px;
 
}


@media screen and (max-width: 480px) {
    .ParentServeBox {
        padding: 10px;

    }
}

.Barindustriesweserve{
    margin: auto;
    margin-top: 7px;
margin-bottom: 7px;
    height: 3px;
    width: 340px;
    background: rgb(231, 112, 33);
}

@media screen and (max-width: 480px) {
    .Barindustriesweserve {
        width: 170px;
    }
}