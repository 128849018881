.serviceBox {
    border: 1px black;
    width: auto;
    height: 100%;
    background: #fff;
    box-shadow: 0 0 23px rgb(0 0 0 / 10%);
    text-align: center;
    border-radius: 15px;
    padding: 30px;
    /* visibility: visible;
    animation-duration: 2s;
    animation-name: fadeInLeft; */
    animation: 1s ease-out 0s 1 slideInLeft;
}

/* .serviceBox:hover  {
 
    transform: rotate(360deg);
    transition: transform 0.8s;
  }
   */
  @media screen and (max-width: 680px) {
    .serviceBox:hover  {
        /* transform: rotateY( 60deg ) ; */
        transform: none;
        transition: none;
      }
  }

@media screen and (max-width: 480px) {
    .cartsServices {
      padding-bottom: 20px;
    }
}

.serviceIcon {
    max-width: 300px;
    max-height: 200px;
}

.HowWeWorkHeader {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 30px;
}

.ServiceSecondRow{
    padding-top: 22px;
    padding-bottom: 28px ;
}

.BarHowwework{
    margin: auto;
    margin-top: 7px;
    height: 3px;
    width: 170px;
    background: rgb(231, 112, 33);
}