.ProcessMain {
    padding-top: 25px;
    padding-bottom: 25px;
}

.HeaderOfProcess {
    text-align: center;
    padding-bottom: 20px;
}

.BarDevelopmentprocess {
    margin: auto;
    margin-top: 7px;
    height: 3px;
    width: 335px;
    background: rgb(231, 112, 33);
}

@media screen and (max-width: 480px) {
    .BarDevelopmentprocess {
        width: 170px;
    }
}

.ProcessBox {
    border: 1px black;
    border-radius: 23px;
    padding: 10px 6px 1px 12px;
    height: 100%;
    font-size: 13px;
    display: flex;
    background-color: #f6f6f6;
}

.ProcessRowPadding {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ProcessNum {
    border: 1px solid rgb(231, 112, 33);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background: rgb(231, 112, 33);
    font-size: 23px;
    font-weight: 700;
}

.ProcessNumText {
    color: aliceblue;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.nummain {
    padding-right: 10px;
    padding-top: 10px;
}



@media screen and (max-width: 480px) {
    .ProcessBoxParent {
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 480px) {
    .gapbox {
        padding-bottom: 20px;
    }
}

.DevProcessHead {
    align-items: center;
    padding: 20px;
    display: flex !important;

}

.DevProcessDetailedHead {
    display: flex !important;
    align-items: baseline;
}

.DevProcessIcon {
    display: flex;
    font-size: 90px;
    padding-right: 30px;
}

@media screen and (max-width: 480px) {
    .DevProcessIcon {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .DevProcessPara {
        text-align: justify;
    }
}

@media screen and (max-width: 480px) {
    .DevProcessDetailedHead {
        text-align: center !important;
        width: 100% !important;
    }
}
@-webkit-keyframes fadeInLeftdev {
    from {
        opacity:0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@-moz-keyframes fadeInLeftdev {
    from {
        opacity:0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@keyframes  fadeInLeftdev {
    from {
        opacity:0;
        -webkit-transform: translatex(-100px);
        -moz-transform: translatex(-100px);
        -o-transform: translatex(-100px);
        transform: translatex(-100px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
.in-left {
    -webkit-animation-name: fadeInLeft;
    -moz-animation-name: fadeInLeft;
    -o-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    animation: fadeInLeft 1s ease-in-out;
}
.faderight {
   
    animation: fadeInRight 1s ease-in-out;
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(300px);
    }
    to {
      opacity: 1;
    }
  }