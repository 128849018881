.MVPMain {
    text-align: center;
}
.MVPCon{
    margin: 30px 0px;
}
.rowcendiv{
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    flex-direction: column !important;
   
}
.paddingofflow{
    padding: 10px 0px;
}
.dotstyle{
    font-size: 1.2rem;
    color: red;
    border-radius: 50%;
}
.number:hover{
    background-color: red;
}
.number-text:hover{
  color: red;
}
.number-text{
    font-size: 1.2rem;
}
.pointscss{
    text-align: justify;
}
.headingflow{
font-size: 1.2rem !important;
font-weight: 400 !important;
}
.headersteps{
    position: absolute;
    top:-24px
}
.number{
    transform-style: preserve-3d;
    display: inline-block;
    background-color: #333;
    color: #fff;
    padding: 5px 18px;
    /* border-radius: 50%; */
    margin-right: 5px;
    font-weight: bold;
}
  .widthresponsive{
margin: 0px;
}
.pointsfont{
    font-size: 1.4rem;
    padding: 0px;
    margin-bottom: 30px;
}
.cardspadiing{
    padding: 20px 60px;
}
.mvpheadimgtext{
    background: antiquewhite;
    padding: 17px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: rgb(66, 59, 12);
    font-size: 4.7rem;
    width: 86%;
    opacity: 0.95;
    z-index: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    font-weight: 600;
}
.line {
    position: absolute;
    top: calc(45% + 5rem); /* Adjust this value based on your text size */
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 2px;
    background-color: rgb(66, 59, 12); /* Same color as text */
    z-index: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
.ContentMVPBG{
    background-color: #f6f6f6;
    padding-top: 20px;
    padding-bottom: 40px;
}

.ProcessMVPImg {
    margin: auto;
    display: flex;
}

.MVPHeader {
    padding-top: 40px;
    padding-bottom: 20px;
    text-transform: uppercase;
}
.mvbbequalgap{
    margin: 60px 0px;
}
.contentMVP {
    justify-content: center;
    text-align: justify;
    /* padding-left: 80px;
    padding-right: 80px; */
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
}
@media screen and (max-width: 740px) {
    .cardspadiing{
        padding: 20px 0px;
    }
}
@media screen and (max-width: 480px) {
    .contentMVP {       
        padding-left: 10px;
        padding-right: 10px;
    }
}


.MVPBar{
    margin: auto;
    margin-top: 7px;
    height: 3px;
    width: 880px;
    background: rgb(231, 112, 33);
}

@media screen and (max-width: 480px) {
    .MVPBar {
        width: 190px;
    }
}


.MVPHeader2{
    padding-top: 20px;
    text-align: justify;
}


.MVPHeaderOurProcess{
    padding-top: 40px;
}

.contentMVP2{
    padding-top: 20px;
    justify-content: center;
    padding-left: 80px;
    padding-right: 80px;
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
}

@media screen and (max-width: 480px) {
    .contentMVP2 {       
        padding-left: 10px;
        padding-right: 10px;
  
    }
}

.BannerMVPMid{
    width: 80%;
    height: 400px;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media screen and (max-width: 480px) {
    .InfographicMVP {
        width: 90% !important;
        height: 70% !important;
    }
}
@media screen and (max-width: 740px) {
    .widthresponsive{
       margin: 15px;
    }
.mvpheadimgtext{
    background: antiquewhite;
    padding: 17px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: rgb(66, 59, 12);
    font-size: 1rem;
}
.line {
    position: absolute;
    top: calc(0% + 5rem);
    left: 50%;
    transform: translateX(-50%);
    width: 68%;
    height: 2px;
    background-color: rgb(66, 59, 12);
    z-index: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
}