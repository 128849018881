.stickyButton {
    z-index: 200;
    position: fixed;
    top: 25%;
    right: 1px;
    float: right;
    background: #141414;
    text-align: center;
    color: white;
    padding: 15px 5px 5px 5px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    cursor: pointer;
}

.GetaQuoteTextStyle{
    text-decoration: none;
    color: white;
}

.GetaQuoteTextStyle:hover{
   color: rgb(231, 112, 33);
}