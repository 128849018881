/* .OurVisonMission {
    margin-top: 50px;
    padding: 20px 10px 20px 10px;
    text-align: center;
    font-size: 54px;
    line-height: 64px;
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
    font-weight: 600;
} */

.OurVisonMission {
    margin-top: 50px;
    padding: 10px;
}


.VisionHeadingH1 {
    text-align: center;
}

.Vision_content {
    padding: 60px 10px 10px 10px;
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
    padding-right: 40px;
    text-align: justify;
}

@media screen and (max-width: 480px) {
    .Vision_content {
        padding-top: 0;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.MissionHeadingH1 {
    text-align: center;
}

.Mission_Content {
    padding: 83px 10px 10px 10px;
    font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
    padding-left: 40px;
    text-align: justify;
}

@media screen and (max-width: 480px) {
    .Mission_Content {
        padding-left: 10px;
        padding-right: 10px;
    }
}


.Vision_Container {
    margin-top: 55px;
    margin-bottom: 55px;
}

.Mission_Container {
    margin-top: 55px;
    margin-bottom: 55px;
}


.Vision_img {
    width: 100%;
    border-radius: 2%;
}

.Mission_img {
    width: 100%;
    border-radius: 2%;
}