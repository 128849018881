.Form {
    background-color: #f6f6f6;
}
.links{
    font-size: 13px;
    font-weight: 600;
}
.ContactUs {
    font-size: 60px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 30px;
    padding-top: 30px;
}

.text-box-contact-form {
    width: 100%;
    padding: 8px;
    outline: none;
    border: 1px solid #c2c2c2;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    margin-top: 10px;
}

.gap-top {
  margin-top: 9px;
}

.padding-div {
    padding: 70px !important;
    padding-top: 5px  !important;
    font-size: 15px !important;
}
@media screen and (max-width: 480px) {
    .padding-div {
        padding: 20px !important;
    }
}

.SubmitBtn {
    font-size: 18px;
    padding: 13px 40px 13px 40px;
    margin-top: 10px;
    background-color: black;
    color: white;
}

.img-contact {
    padding: 30px;
}

.head-contact {
    margin-top: 50px;
    margin-bottom: 50px;

}

.back-to-home-page {
    padding: 20px 0px 0px 500px;
}

@media screen and (max-width: 480px) {
    .back-to-home-page {
        padding: 20px 0px 0px 100px;
    }
}

.CallUs_EmailUs {
    font-size: 20px;
    font-weight: 500;
    margin-top: 10%;
    padding: 70px;
    text-align: left;
    padding-top: 50px;
    padding-bottom: 50px;

}

.links{
    display: block;
}

.links:link {
    text-decoration: none;
    color: #2cc9fc;
}

.links:hover {
    text-decoration: underline;
    color: rgb(231, 112, 33);
}

@media screen and (max-width: 480px) {
    .padding-div {
        padding: 10px;
    }

    .CallUs_EmailUs {
        padding-top: 20px;
        padding-bottom: 0px;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.Contact-address1 {
    margin-bottom: -10px;
    margin-left: 50px;
    margin-right: 10px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
}

@media screen and (max-width: 480px) {
    .Contact-address1 {
        padding: 10px;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
    }
}

    .back-to-home-page {
        color: #2cc9fc !important;
        cursor: pointer;
    }

    .back-to-home-page :hover {
        color: rgb(231, 112, 33);
    }

    .contactDisplay{
        display: flex;
    }

    @media screen and (max-width: 480px) {
        .contactDisplay {
           display: block;
        }
    }
    .Get_Direction{
        color:"red" !important ;
    }