.Card {
    border: 1px black;
    border-radius: 15px;
    width: auto;
    height: 100%;
    text-align: left;
    font-family: (--textfont);
    box-shadow: 0 0 23px rgb(0 0 0 / 10%); 
    background: #fff;
    padding: 24px;
}

.price {
    color: grey;
    font-size: 22px;
}

.Cart {
    text-align: center;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 5px;
}

.card-heading-color {
    font-size: 28px;
    font-style: normal;
    padding-top: 18px;
    color: #ff5000 !important;
}

.card-para-career {
    font-family: Gilroy,-apple-system,BlinkMacSystemFont,Raleway,sans-serif!important;
    text-align: justify;
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
    font-style: normal;
    color: #4d4d4d;
}

.careerImg{
    height: 200px;
    width: 100%;
}

@media screen and (max-width: 480px) {
    .CardParent {
      padding-bottom: 20px;
    }
}


