.carousel-indicators {
    margin-bottom: -1.5rem !important;
}

.carousel-indicators [data-bs-target] {
    margin-top: 20px !important;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50% !important;
    background-color: pink;
}

.container-carousle-life-at {
    background-color: rgb(255, 255, 255);
    padding-top: 42px;
    padding-left: 50px;
    align-items: center;
    padding-bottom: 30px;
}

@media (max-width: 480px) {
    .container-carousle-life-at {
        padding-left: 0px;
    }
}

/* @media (max-width: 1366px) {
    .container-carousle-life-at {
        color: #555;
        margin: 0 auto;
        width: 100%;
    }
} */

.crousel-inner {
    padding-right: 25px;
    padding-left: 30px;
}


.carousel-control-next-icon, .carousel-control-prev-icon {
    height: 0px !important;
    width: 0px !important;

}


.image-upmove-image {
    border-radius: 5px;
}


.crowsel-crowsel-text-head-para-txt-crow {
    vertical-align: middle;
    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;
}

.d-block-w-100-fistcrow-small-one {
    width: 100%;
    height: 70%;
    margin-bottom: 20px;
    border-radius: 15px;
}


@media screen and (max-width: 480px) {
    .crowsel-lifeat-heading-lifeat {
        padding-left: 10px;
        padding-right: 15px;

    }

    .crowsel-lifeat-para-para-life {
        padding-left: 5px;
        padding-right: 5px;

    }

    .crowsel-crowsel-text-head-para-txt-crow {
        padding: 0px;
    }

}