.imag-mid-blue-banner-midd {
    width: 100%;

}

.banner-LifeatPerfectkodeMiddle {
    background: url('../../../assets/images/Careermiddle.png');
    padding: 50px;
    background-position: center bottom;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: 55px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 680px) {
    .banner-LifeatPerfectkodeMiddle {
        font-size: 25px;
        font-weight: 600;
    }
}