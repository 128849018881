.cardBody {
  padding: 20px;
}

.cardBody .card {
  width: 100%;
  height: 350px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* background-color: rgb(255, 247, 241) !important; */
  background-color: #fff;
  padding: 20px;
  /* border-end-end-radius: 80px !important; */
  border-radius: 10px;
  border: none !important;
}
.swiper-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(
    to left,
    rgb(163 232 254),
    rgba(0, 0, 0, 0)
  ) !important;
}

.swiper-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(
    to right,
    rgb(163 232 254),
    rgba(0, 0, 0, 0)
  ) !important;
}


@media screen and (max-width: 1000px) {
  .cardBody {
    display: flex;
    flex-direction: column;
  }
}

.quotes-icon svg {
  color: rgb(96, 94, 94) !important;
}

.cardInfo {
  padding-bottom: 20px;
}
.cardInfo h4 {
  font-size: 16px;
  text-align: center;
  margin-top: 6%;
  font-family: Poppins;
  color: rgb(10, 10, 10);
  letter-spacing: 2px;
}
.cardInfo h3 {
  text-align: center;
  font-size: 26px;
  margin-top: 4%;
  font-weight: 500;
  font-family: Poppins;
  color: #f19430;
}
.card svg {
  height: 3em !important;
  width: 3em !important;
  color: rgb(61, 61, 61);
  position: absolute;
  top: 5px;
  left: 10%;
}
.imgCircle {
  background-color: rgb(203, 203, 203) !important;
  border-radius: 50px !important;
}
.imgCircle p {
  padding: 10px 10px 0 10px;
  text-align: center;
}
@media screen and (max-width: 540px) {
  .cardInfo h4 {
    font-size: 10px;
    text-align: center;
    margin-top: 6%;
    font-family: Poppins;
    color: rgb(10, 10, 10);
    letter-spacing: 2px;
  }
  .cardInfo h3 {
    text-align: center;
    font-size: 16px;
    margin-top: 4%;
    font-weight: 500;
    font-family: Poppins;
    color: #f19430;
  }
}