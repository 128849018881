/* @import url('../../assets/globalvariable/Globalvariable.css'); */
.main-box {
    padding: 50px 50px 10px 50px ;
    background: rgb(59, 57, 57);
    width: 100%;
}

.space-btn{
    display: flex !important;
    justify-content: space-between !important;
}

.h4-text-decoration{
    color: #fff;
    font-weight: bold;
    text-align: left;
}

.Footer-List {
    color: #fff;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 13px;
    list-style-type: none;
    letter-spacing: 2px;
    font-family: Poppins;
    font-family: (--textfont);
    text-align: left;
    padding-bottom: 10px;
    /* font-weight: 100 !important; */
}
.Footer-List li, a, p{
    padding-bottom: 5px;
}

.hover-with-colors:link{
    text-decoration: none;
    color: #2cc9fc;
        
}

.hover-with-colors:hover{   
    color: rgb(231, 112, 33);
    text-decoration: none;
}

.hover-with-colors{
    text-decoration: none;
    color: #2cc9fc;
}

.social-icon{
    display: flex;
    margin: 5px;
    font-size: 35px;
    margin-top: 5%;
    justify-content: center;
}
.social-icon a{
    padding: 7px;
}
.social-icon a:hover img{
    width: 50px !important;
    transition: all 0.5s;
}

.Copyright{
    text-align: center;
    letter-spacing: 1px;
    color: rgb(188, 185, 185);
    font-family: Poppins;
    font-size: 13px;
    margin-top: -0.5% !important;
}

.ContactUs{
    margin: auto;
    font-size: 13px;
}

.Logo{
    text-align:center;
    font-family: (--textfont);
    font-size: 13px;
}
.Logo p{
    letter-spacing: 3px;
}