text-heading-para-lifeat{
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: black;
}


.carousel-fade{
    padding-left: 30px;
    padding-right: 30px;
  
}

.container-carousle-life-at-second-page{
    height: 140%;


    background: linear-gradient(#2cc9fc, rgb(231,112,33)) !important;

    padding-left:60px;
    padding-right: 60px;
    padding-bottom: 25px;
    padding-top:25px
}

 

.fun-fun-heading-activity{
    padding-top:20px;
    padding-bottom:10px;
    text-align: center;

}

.para-fun-para-lifeat-activity{
    margin-bottom: 40px;
} 

.fun-heading-para-crow-fun{
    vertical-align: middle;
    padding-top: 10px;
    /* padding-left: 50px; */
    padding-right: 50px;
}


/* .d-block-w-100{
    width:100%;
    height: 50% !important;
}

.d-block-w-100-hh{
    width:100%;
    height:66% !important;
}

.d-block-w-100-pp{
    width:100%;
    height:71% !important;

} */

.image-carosel-width{
    width: 100%;
    border-radius: 15px;
   
}
