.cardBox{
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    overflow: hidden;
    position: relative;
    border-color: #c1c1c1;
    padding: 43px 30px 65px;
    background: rgb(150, 194, 226);
    position: relative;
    transition: 0.5s;
    margin-bottom: 5%;
    border-radius: 10px;
    height: 350px;
}
.descriptionText{
    font-size: 16px;
}
.cardBox:hover{
    background-color: #fff;
    
}
.cardBox:hover .content-box h5{
    color: black !important;
}
.cardBox:hover .content-box div{
    color: black;
}
.cardBox:hover .number-box{
    color: rgb(222, 220, 220);
       
}
.cardBox:hover .icon-box{
    background-color: rgb(75, 183, 255);
}
.content-box{
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.content-box h5{
    font-weight: 700 !important;
    color: #fff !important;
    font-size: 27px !important;
    padding-bottom: 10px;
}
.content-box div{
    padding-bottom: 20px;
}
.content-box a{
text-decoration: none;
}
.number-box{
    position: absolute;
    left: -10px;
    top: 0;
    font-size: 100px;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    line-height: 72px;
    color: #8197b9;
    transition: 0.5s;
}
.icon-box{
    position: absolute;
    bottom: -88px;
    right: -88px;
    width: 180px;
    height: 170px;
    color: #fff;
    background: #46416B;
    
    border-radius: 68px;
}
.flaticon-tablet{
    position: absolute;
    top: 25px;
    left: 10px;
    font-size: 35px;
    line-height: 1;
    
}
.flaticon-tablet svg{
    vertical-align: middle;
    width: 88px;
    height: 40px;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
padding-left:  1px !important;
padding-right: 1px !important;
/* margin-bottom: 5%; */
}