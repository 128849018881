.AboutUsImg{
    width: 100%;
    border-radius: 2%;
}

.Content{

    font-family: Gilroy,-apple-system,BlinkMacSystemFont,Raleway,sans-serif!important;
    font-size: 14px;
    
    padding: 10px 10px 10px 10px;
}

.Passion{
    padding-left: 40px;
    
}

@media screen and (max-width: 480px) {
    .Passion {
        padding-left: 0;
        padding-top: 20px;
}
}

.Relationships{
    padding-right: 40px;
}


@media screen and (max-width: 480px) {
    .Relationships {
        padding-left: 0;
}
}

.Integrity{
    padding-left: 40px;
}

@media screen and (max-width: 480px) {
    .Integrity {
        padding-left: 0;
        padding-top: 20px;
}
}

@media screen and (max-width: 480px) {
    .Relationships {
        padding-left: 0;
}
}

.AboutUsValue1{
    margin-top: 45px;

    margin-bottom: 55px;
}

@media screen and (max-width: 480px) {
    .AboutUsValue1 {
        margin-top: 15px;
}
}

.AboutUsValue2{
    margin-top: 55px;
    margin-bottom: 55px;
}

.AboutUsValue3{
    margin-top: 55px;
    margin-bottom: 45px;
}

.ArrowIcon{
color: #00FF00;
}