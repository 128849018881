.button-wrapper {
  
    display: flex;
    flex-wrap: wrap; 
  }

  .border-red {
    border: 1px solid white !important;
  }
  
  .border-green {
    border: 1px solid green !important;

  }
  .imgwid{
    width: 100%;
    height: 200px;
  }
  .imgwidm{
    width: 100px;
    height: 200px;
  }
  .modal{
    --bs-modal-width: 1000px;
  }
  .carousel-control-prev-icon{
    width: 2rem !important;
    height: 2rem !important;
  }
  .carousel-control-next-icon{
    width: 2rem !important;
    height: 2rem !important;
  }
  
  .modal-body-scrollable {
    max-height: 60vh; /* Adjust the height as per your requirement */
    overflow-y: auto;
  }
  .card-Header {
    min-height: 95px; /* Adjust as needed */
  }
  .card-body img {
    height: 200px;
  }
  
  .card .card-text:empty::before {
    content: "  ";
    color: #999;
  }
  