.portbanner {
    width: 100%;
    height: auto;
}

.Portfoliofooter {
    padding-top: 30px;
    background-color: rgb(0, 0, 0);
    color: #ffffff;
}

.PortfolioBtn {
    border: #2cc9fc;
}

.padiingatbottom {
    padding-bottom: 30px;
}

.bg-red-color {
    background-color: red !important;
}

.IntroContentPortfolio {
    padding: 40px;
    font-weight: 400;
    font-size: larger;
}

.MarginBottom1rem {
    margin-bottom: 1rem;
}

.OnTaps {
    display: flex;
    padding-left: 10px;
    gap: 35px;
}

.TapPortfolioHead {
    padding-top: 50px;
}

.Onclick_Tech {
    padding: 10px;
    padding-left: 0;
    color: #2cc9fc;
    cursor: pointer;    
}

.Onclick_Tech :hover {
    color: rgb(231, 112, 33);    
}

.Onclick_Tech.active {
    text-decoration: underline;  
    color: rgb(231, 112, 33);    
}

.TapBtns {
    padding: 10px 24px;
    border-radius: 6px;
    border: 1px gray;
}

.OnTapContent :hover {
    background-color: rgb(231, 112, 33);
}

.OnTapContent.active {  
    color: rgb(231, 112, 33);    
}