@import url('../../assets/globalvariable/Globalvariable.css');


.navbar {
    display: block;
    color: black !important;
    font-family: (--textfont);
    background-color: white;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}
.dropdown-content a:hover{
    color: rgb(231, 112, 33) !important;
}

.Header_list {
    font-weight: 600;
    margin: 14px 18px 14px;
    font-family: (--textfont);
    font-size: 18px;
    letter-spacing: 1px;
    
}

.Header_list :link {
    text-decoration: none;
    color: #2cc9fc !important;
}

.Header_list  {
    color: #2cc9fc !important;
}
.Header_list :hover {
    color: rgb(231, 112, 33) !important;
}

.Header_list a:hover {
    color: rgb(231, 112, 33) !important;
}

.dropbtn {
    font-weight: 600;
    margin: 16px 18px 14px;
    font-family: (--textfont);
    font-size: 18px;
    cursor: pointer;
    color: #2cc9fc;
    letter-spacing: 1px;
}
.unselected-color{
    color: #2cc9fc !important;

}
.selected-color {
    color: rgb(231, 112, 33) !important;


}

.dropbtn:hover {
    color: rgb(231, 112, 33) !important;
}
.dropdown:hover .dropdownicon svg{
    color: rgb(231, 112, 33) !important;
} 

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 260px;
    font-size: 16px;
    margin-top: 1px;
    z-index: 999;
    letter-spacing: 1px;
    font-weight: 450;
}


.dropdown-content a {
    color: #2cc9fc;
    padding: 10px 30px 10px 30px;
    text-decoration: none;
    display: block;
    
}

.dropdown-content a:hover {
    color: rgb(231, 112, 33);
}

.dropdown:hover .dropdown-content {
    display: block;
    
}

.me-auto{
    padding-left: 10px !important;
}

.header_Display_flex{
    display: flex;
}
.padding_10px{  
    width: 100%;
}

@media screen and (max-width: 480px) {
    .navbar-brand{
      margin-right: 0rem !important;
    }
  }

 .dropdownicon{
     font-size: 20px;

 }
 .dropdownicon svg{
    width: 0.6em !important;
    color: gray;
 }
 