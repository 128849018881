.image-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text {
  margin-top: 20px;
}
.TechImg {
  height: 120px !important;
  width: 120px !important;
}
.mobile-img{ 
    height: 80px !important;
    width: 80px !important;
}
@media screen and (max-width: 540px) {
.TechImg {
  height: 75px !important;
  width: 73px !important;
}
.text {
  font-size: 16px !important;
}

}