

.FAQ{
    align-items: left;
    padding-top: 30px !important;  
    /* padding-left:60px;
    padding-right: 60px; */
    padding-left:24px;
    padding-right: 24px;
    padding-bottom: 50px;
    margin-top: 10px;
    
    
}

.tgg-text-tgg{
    padding-left:30px;
    padding-top:10px !important;
    height: 20px;
    
}

.toggle-button{
    width:100%;
    
}

.dropdown-button-text{
    color: black !important;
    width:100%;
    height:50px;
    background-color: white !important;
    text-align: left;
    /* padding-left: 20px; */
}
.border_bottom{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid gray;
 
}

.button-dropdown-button-text-text123{
    margin-left: 10px;
    margin-right: 20px;
    color: black !important;
    width:70%;
    height:50px;
    background-color: white !important;
    text-align: center;
    border: none !important;
    
}

/* .iconsaccordion{
    text-align: right;
} */

.accordion-button{
    padding: 0 !important;
}