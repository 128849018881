.RedesignBannerContainer {
  position: relative;
  text-align: center;
  margin-top: 50px; /* Adjust as needed */
  overflow: hidden; /* Ensure overflow is hidden to contain the scrolling text */
}

.RedesignBannerImg {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.typewriter-text {
  width: 50vw;
  max-width: 90%; /* Adjust as needed */
  display: flex;
  flex-wrap: wrap;
  white-space: pre-inline;
  overflow: hidden;
color: #191515;
  font-family: monospace;
  font-size: 2em;
  position: absolute;
  top: 50%;
  left: 28%; /* Adjust to center horizontally */
  transform: translate(-50%, -50%);
  text-align: center; /* Center text */
  padding: 10px; /* Add padding to improve visibility */

}
