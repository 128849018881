.GetaQuoteFormMain {
    padding: 20px !important;
    border: 2px black;
    border-radius: 10px;
    box-shadow: 0 0 23px rgb(0 0 0 / 15%); 
}

.GetaQuoteFormAboveMain {
    padding: 30px;
    top: 20;
}

.GetaQuoteSubmitBtn {
    font-size: 18px;
    padding: 13px 40px 13px 40px;
    margin-top: 10px;
    background-color: black;
    color: white;

}


.btn-align {
    text-align: center;
    padding-bottom: 20px;

}

.text-box-courses-form {
    width: 100%;
    padding: 8px;
    outline: none;
    border: 1px solid #c2c2c2;
    border-radius: 5px;

}