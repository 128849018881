.content-opportunities-career-data{
    border: 1px black;
    width: 100%;
    height: 80%;
    background: #fff;
    box-shadow: 0 0 23px rgb(0 0 0 / 10%);
    text-align: center;
    border-radius: 15px;
    padding: 10px;
    padding-top: 1px; 
    margin-bottom: 10px;
}

.careerdataoppor{
    text-align: center;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-right: 30px;
}

.app-testing-heading-cap{
    text-align: center;
    padding: 10px;
}

.para-description-first{
    padding-top: 10px;
}

 .app-testing-oppr-data-career{
    margin-left: 45px;
}     

.Underline_Jobprofile{
    margin-top: 5px;
    height: 3px;
    width: 125px;
    background: rgb(231, 112, 33);
}

.padding-bottom-job-profile{
    padding-bottom: 40px;
}