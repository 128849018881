
.StrategicBar {
    background: rgb(231, 112, 33);
    width: 30%;
    border-radius: 5px;
    height: 3px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: auto;
}
.h-down-line-under5{
    margin-top: 5px;
    height: 3px;
    width: 150px;
    margin-bottom: 30px;
    background: rgb(231, 112, 33);
}
@media screen and (max-width: 480px) {
    .StrategicBar {       
        width: 190px;
    }
    .StrategicSolutionsimg {
        width: 80% !important;
        height: 350px !important;
    }
}
@media screen and (max-width: 580px) {
.StrategicSolutionsimg {
    width: 80% !important;
    height: 350px !important;
}}
.InfraContent{
text-align: left;
font-family: Gilroy, -apple-system, BlinkMacSystemFont, Raleway, sans-serif !important;
}

.WebHeaderInfra{
    padding-top: 1px;
}

.StrategicSolutionsimg{
    width: 80%;
    height: 500px;
}
